var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('RegisterSettings',{model:{value:(_vm.settings),callback:function ($$v) {_vm.settings=$$v},expression:"settings"}}),(_vm.settings.ready)?_c('div',[(_vm.settings.view == 'tab')?_c('RegisterTabs',{directives:[{name:"touch",rawName:"v-touch",value:({
        left: () => _vm.next(),
        right: () => _vm.prev(),
      }),expression:"{\n        left: () => next(),\n        right: () => prev(),\n      }"}],attrs:{"person":_vm.person,"showAbsenceToggleButton":_vm.showAbsenceToggleButton,"showAbsences":_vm.settings.showAbsences,"showEvents":_vm.settings.showEvents,"focus":_vm.settings.focus},on:{"input":(value) => (_vm.settings.focus = value)}}):(_vm.settings.view == 'list')?_c('RegisterLessons',{directives:[{name:"touch",rawName:"v-touch",value:({
        left: () => _vm.next(),
        right: () => _vm.prev(),
      }),expression:"{\n        left: () => next(),\n        right: () => prev(),\n      }"}],attrs:{"person":_vm.person,"showAbsenceToggleButton":_vm.showAbsenceToggleButton,"showAbsences":_vm.settings.showAbsences,"showEvents":_vm.settings.showEvents,"startDate":_vm.findMonday(this.settings.focus),"endDate":_vm.addDays(_vm.findMonday(this.settings.focus), 6)}}):_c('RegisterCalendar',{directives:[{name:"touch",rawName:"v-touch",value:({
        left: () => _vm.next(),
        right: () => _vm.prev(),
      }),expression:"{\n        left: () => next(),\n        right: () => prev(),\n      }"}],attrs:{"person":_vm.person,"showAbsences":_vm.settings.showAbsences,"showEvents":_vm.settings.showEvents,"type":_vm.settings.view,"focus":_vm.settings.focus}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }