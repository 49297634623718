<template>
  <PersonRegister :person="person"></PersonRegister>
</template>
<script>
import { defineComponent } from "vue";
import PersonRegister from "common/components/Register/PersonRegister.vue";

export default defineComponent({
  components: { PersonRegister },
  props: ["person"],
});
</script>
