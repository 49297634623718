<template>
  <div>
    <RegisterSettings v-model="settings"></RegisterSettings>
    <div v-if="settings.ready">
      <RegisterTabs
        v-touch="{
          left: () => next(),
          right: () => prev(),
        }"
        v-if="settings.view == 'tab'"
        :person="person"
        :showAbsenceToggleButton="showAbsenceToggleButton"
        :showAbsences="settings.showAbsences"
        :showEvents="settings.showEvents"
        :focus="settings.focus"
        @input="(value) => (settings.focus = value)"
      ></RegisterTabs>

      <RegisterLessons
        v-touch="{
          left: () => next(),
          right: () => prev(),
        }"
        v-else-if="settings.view == 'list'"
        :person="person"
        :showAbsenceToggleButton="showAbsenceToggleButton"
        :showAbsences="settings.showAbsences"
        :showEvents="settings.showEvents"
        :startDate="findMonday(this.settings.focus)"
        :endDate="addDays(findMonday(this.settings.focus), 6)"
      />
      <RegisterCalendar
        v-else
        :person="person"
        :showAbsences="settings.showAbsences"
        :showEvents="settings.showEvents"
        :type="settings.view"
        v-touch="{
          left: () => next(),
          right: () => prev(),
        }"
        :focus="settings.focus"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import RegisterCalendar from "common/components/Register/RegisterCalendar.vue";
import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import RegisterSettings from "common/components/Register/RegisterSettings.vue";
import RegisterTabs from "common/components/Register/RegisterTabs.vue";

import { findMonday, today, addDays } from "common/utils/date.js";
export default defineComponent({
  name: "Register",
  components: {
    RegisterCalendar,
    RegisterLessons,
    RegisterSettings,
    RegisterTabs,
  },
  props: {
    person: { type: Object },
  },
  data() {
    return {
      settings: {
        focus: "",
        showAbsences: true,
        showEvents: false,
        view: "list",
      },
    };
  },
  computed: {
    steps() {
      return this.view === "day" ? 1 : 7;
    },
    showAbsenceToggleButton() {
      if (!this.person || !this.person.schoolClass) {
        return false;
      }
      return (
        this.settings.showAbsences &&
        this.$_classTeacherOf.some((el) => el.id === this.person.schoolClass.id)
      );
    },
  },

  methods: { addDays, findMonday },
  created() {
    if (this.date) {
      this.settings.focus = this.date;
    } else {
      this.settings.focus = today();
    }
  },
});
</script>
